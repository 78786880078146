module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[],"defaults":{"formats":["auto","webp"],"placeholder":"blurred","quality":80}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://site-qa.jawoodle.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"JaWoodle (develop)","short_name":"JaWoodle (develop)","start_url":"/","background_color":"#5c1395","theme_color":"#5c1395","display":"minimal-ui","icon":"src/assets/images/jawoodle-pwa.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a89c78055232d790bc83753e7f87f786"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"./config"},
    }]
